import { createBounds, createPoint } from "@luciad/ria/shape/ShapeFactory";
import { WGS84 } from "./Reference";
import { WebGLMap } from "@luciad/ria/view/WebGLMap";
import { CitySource } from "./layers/CitySource";
import { getFitBounds } from "@luciad/ria-sample-common/FitBoundsUtil";
import { getAppConfig } from "./AppConfig";

export class Navigator {
  private _map: WebGLMap;

  constructor(map: WebGLMap) {
    this._map = map;
  }

  public async navigateTo(citySource: CitySource, animate: boolean = true) {
    let layers = await citySource.layers;
    const firstLayer = layers[0];
    const bounds = await getFitBounds(firstLayer);
    return this._map.mapNavigator.fit({ bounds: bounds, animate: animate });
  }

  public async navigateHome() {
    const appConfig = await getAppConfig();
    if (this._map.reference.identifier === "EPSG:4978") {
      this._map.mapNavigator.lookFrom(
        createPoint(WGS84, appConfig.camera.defaultPosition.eyePoint),
        appConfig.camera.defaultPosition.yaw,
        appConfig.camera.defaultPosition.pitch,
        appConfig.camera.defaultPosition.roll
      );
    } else {
      this._map.mapNavigator.fit({
        bounds: createBounds(WGS84, [16.5337439, 0.065, 49.2975436, 0.05]),
        animate: true
      });
    }
  }
}