import { SwipeController } from "@luciad/ria/view/controller/SwipeController";
import { CompositeController } from "@luciad/ria-sample-common/controllers/CompositeController";
import { WebGLMap } from "@luciad/ria/view/WebGLMap";
import { URLUtil } from "@luciad/ria-sample-common/URLUtil";
import { getAppConfig } from "./AppConfig";
import { ContentManager } from "./ContentManager";
import { ControlGroup } from "@luciad/ria-sample-common/control/ControlGroup";
import { Ruler3DController } from "./ruler3d/Ruler3DController";
import { Ruler3DPanel } from "./ruler3d/Ruler3DPanel";
import { EffectsPanel } from "./controls/EffectsPanel";
import { LightPanel } from "./controls/LightPanel";
import { CzechLayerTreeControl } from "./controls/CzechLayerTreeControl";
import { CzechSwipeUi } from "./controls/CzechSwipeUi";

export function addControls(map: WebGLMap) {
  const controlGroupDomId = new ControlGroup(map).getDomId();

  const layerTreeControl = new CzechLayerTreeControl(map, {
    parentId: controlGroupDomId,
    noLayerDelete: true,
    title: "MAPOVÉ VRSTVY"
  });
  layerTreeControl.close();

  const rulerController = new Ruler3DController({ enabled: false });
  map.controller = rulerController;
  const ruler3DControl = new Ruler3DPanel(map, rulerController, { parentId: controlGroupDomId });
  ruler3DControl.close();

  const effectsControl = new EffectsPanel(map, { parentId: controlGroupDomId });
  effectsControl.close();

  const lightControl = new LightPanel(map, { parentId: controlGroupDomId });
  lightControl.close();
}

export function addSwipeController(map: WebGLMap) {
  const isSwipeController = (controller) => {
    return controller instanceof SwipeController ||
           (controller instanceof CompositeController && controller.delegates[0] instanceof SwipeController);
  };
  const getOriginalMapController = (map) => {
    if (isSwipeController(map.controller)) {
      if (map.controller instanceof CompositeController) {
        return map.controller.delegates[1];
      }
      return null;
    }
    return map.controller;
  };
  const setController = (map, controller) => {
    try {
      map.controller = controller;
    } catch (e) {
      map.controller = null;
    }
  };

  const swipeButton = document.createElement("a");
  swipeButton.classList.add("btn", "btn-secondary", "btn-map", "swipe-button");
  // @ts-ignore
  swipeButton.role = "button";
  swipeButton.title = "Porovnat vrstvy";

  swipeButton.addEventListener("click", (ev) => {
    ev.preventDefault();
    if (!isSwipeController(map.controller)) {
      const swipeController = new SwipeController();
      const sampleSwipeUI = new CzechSwipeUi(swipeController);
      sampleSwipeUI.on("StopButtonClicked", () => {
        setController(map, getOriginalMapController(map));
      });
      if (map.controller) {
        // if there already was a controller on the map, keep it and put a SwipeController in front of it
        const compositeController = new CompositeController();
        compositeController.appendController(swipeController);
        compositeController.appendController(getOriginalMapController(map));
        setController(map, compositeController);
      } else {
        setController(map, swipeController);
      }
    }
  });
  map.on("ControllerChanged", (newController, prevController) => {
    swipeButton.classList.toggle("active", isSwipeController(newController));
  });

  const icon = document.createElement("span");
  icon.classList.add("bi", "bi-arrows-expand");
  swipeButton.appendChild(icon);

  const topLeftButtons = document.getElementById("topleftbuttons");
  if(topLeftButtons)
    topLeftButtons.appendChild(swipeButton);
}

export async function addBottomBar(contentManager: ContentManager) {
  const parentE = document.getElementById("btnContainer");

  // contentManager.activeSourceChangedListener = {
  //   onActiveSourceChanged: (source) => {
  //     document.body.classList.add("splash");
  //   }
  // };

  const appConfig = await getAppConfig();
  appConfig.citiesSources.forEach(city => {
    const containerButton = document.createElement("button");
    const image = document.createElement("img");
    const title = document.createElement("p");

    image.src = city.iconUrl;
    image.alt = city.title;
    containerButton.id = city.id;
    containerButton.classList.add("disableforcanvas");
    containerButton.classList.add("btn");
    containerButton.classList.add("btn-secondary");
    title.textContent = city.title;
    containerButton.appendChild(image);
    containerButton.appendChild(title);
    containerButton.classList.add("container");
    containerButton.addEventListener("click", () => {
      contentManager.setActiveSource(city.id);
      document.body.classList.add("splash");
    });
    if(parentE)
      parentE.appendChild(containerButton);
  });

  const buttons = document.querySelectorAll("button.container");

  buttons.forEach(button => {
    button.addEventListener('click', function () {
      if (document.body.classList.contains("chooseArea")) document.body.classList.remove("chooseArea");
      buttons.forEach(btn => btn.classList.remove("active"));
      this.classList.add("active");
    });
  });
}

export async function validateSelectedCity(): Promise<boolean> {
  const queryParameters = URLUtil.queryParameters;
  const messageOverlayText = document.getElementById("messageoverlaytext");

  const appConfig = await getAppConfig();
  const citySelected = !!appConfig.citiesSources.find(source => source.id === queryParameters.obec);
  if (!citySelected) {
    document.body.classList.add("chooseArea");
    if(messageOverlayText)
      messageOverlayText.innerHTML = "Vyberte oblast, kterou chcete zobrazit.";
  }
  return citySelected;
}

export async function validateShareViewParams(): Promise<boolean> {
  const queryParameters = URLUtil.queryParameters;

  let viewParams = false
  if (typeof queryParameters.reference != "undefined" && typeof queryParameters.x != "undefined" && typeof queryParameters.y != "undefined" && typeof queryParameters.z != "undefined" && typeof queryParameters.yaw != "undefined" && typeof queryParameters.pitch != "undefined" && typeof queryParameters.roll != "undefined")
    viewParams = true;
  else
    viewParams = false;

  return viewParams;
}