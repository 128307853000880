/**
 * Based on https://stackoverflow.com/a/26358856/2811075
 * and https://stackoverflow.com/a/9851769/2811075
 */
import { URLUtil } from "@luciad/ria-sample-common/URLUtil";

export function isSafari(): boolean {
  return /constructor/i.test(window.HTMLElement) ||
         (function(p) { return p.toString() === "[object SafariRemoteNotification]"; })(
         !window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));
}

export function checkSafariAndReload(): void {
  // console.log(URLUtil.queryParameters);
  if (!isSafari() || URLUtil.queryParameters.webgl === "2") {
    return;
  }
  var url = window.location.href;
  if (url.indexOf("?") > -1) {
    if (url.at(-1) === "?") {
      url = url + "webgl=2";
    } else {
      url = url + "&webgl=2";
    }
  } else {
    url = url + "?webgl=2";
  }
  window.location.href = url;
}