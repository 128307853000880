import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {WMSTileSetModel} from "@luciad/ria/model/tileset/WMSTileSetModel";
import {WMSImageModel} from "@luciad/ria/model/image/WMSImageModel";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {WMSImageLayer} from "@luciad/ria/view/image/WMSImageLayer";

var webMercatorReference = getReference("EPSG:3857");

export var WMSDataLoader = {
  /**
   * Creates a layer for WMS data.
   *
   * This class uses Web Mercator as reference by default.
   *
   * Summary:
   * - Create a {@link luciad/model/tileset/WMSTileSetModel}
   * - Create a {@link luciad/view/tileset/RasterTileSetLayer}
   *
   * Alternatively, to use single-image WMS:
   * - Create a {@link luciad/model/image/WMSImageModel}
   * - Create a {@link luciad/view/image/RasterImageLayer}
   */
  createLayerBasic: function(url, layerName, tiled, queryable, bounds) {
    var modelOptions = {
      getMapRoot: url,
      layers: [layerName],
      reference: webMercatorReference,
      transparent: true,
      queryLayers: queryable ? [layerName] : [],
      bounds: bounds
    };
    if (tiled) {
      return new WMSTileSetLayer(new WMSTileSetModel(modelOptions), {
        label: layerName + " (WMS)"
      });
    } else {
      return new WMSImageLayer(new WMSImageModel(modelOptions), {
        label: layerName + " (WMS)"
      });
    }
  },
  /**
   * Creates a layer for WMS data.
   *
   * This class uses Web Mercator as reference by default.
   *
   * Summary:
   * - Create a {@link luciad/model/tileset/WMSTileSetModel}
   * - Create a {@link luciad/view/tileset/RasterTileSetLayer}
   *
   * Alternatively, to use single-image WMS:
   * - Create a {@link luciad/model/image/WMSImageModel}
   * - Create a {@link luciad/view/image/RasterImageLayer}
   */
  createLayer: function(capabilities, layersUiState, modelReference, tiled) {

    layersUiState = layersUiState.filter(function(l) {
      return l.isSelected === true;
    });
    var layerName = layersUiState.map(function(l) {
      return l.title
    }).join(", ");
    var layersConfig = layersUiState.map(function(l) {
      return {
        layer: l.layer.name,
        style: l.selectedStyle
      };
    });

    var modelOptions = {
      reference: modelReference
    };

    if (tiled) {
      return new WMSTileSetLayer(WMSTileSetModel.createFromCapabilities(capabilities, layersConfig, modelOptions), {
        label: layerName + " (WMS)"
      });
    } else {
      return new WMSImageLayer(WMSImageModel.createFromCapabilities(capabilities, layersConfig, modelOptions), {
        label: layerName + " (WMS)"
      });
    }
  }
};
