import "@luciad/ria-sample-common/controllers/ui/SampleSwipeUI.css";
import {CzechLayerPicker} from "./CzechLayerPicker";
import {SwipeController, SwipeLineOrientation} from "@luciad/ria/view/controller/SwipeController";
import {Map} from "@luciad/ria/view/Map";
import {Handle} from "@luciad/ria/util/Evented";

const SWIPE_CONTROLLER_CLASS = "lcdSwipeController";
const SWIPE_LINE_CLASS = "lcdSwipeLine";
const SWIPE_INSTRUCTIONS_CLASS = "lcdSwipeInstructions";

const SWIPE_HOVER_CLASS = "lcdSwipeHovering";
const SWIPE_SWIPING_CLASS = "lcdSwipeSwiping";
const SWIPE_HORIZONTAL_CLASS = "lcdSwipeHorizontal";
const SWIPE_VERTICAL_CLASS = "lcdSwipeVertical";

/**
 * Sample implementation of a {@link SwipeController} UI.
 * You can re-use this implementation as-is in your application, modify it as you please or use it as an example
 * to implement your own UI.
 * The look and feel can be tweaked with the variables found at the top of the CSS files in this folder.
 * Aside from other files found in this folder, this UI has no further dependencies on the rest of the LuciadRIA
 * sample framework.
 */
export class CzechSwipeUi {

  private readonly _swipeController: SwipeController;
  private readonly _layerPicker: CzechLayerPicker;

  private _map: Map | null;

  private _controllerActivationListener: Handle | null;
  private _controllerDeactivationListener: Handle | null;
  private _swipeLocationListener: Handle | null;
  private _orientationChangedListener: Handle | null;
  private _hoverChangeListener: Handle | null;
  private _swipingChangeListener: Handle | null;
  private _initialSwipeListener: Handle | null;
  private _instructions: HTMLDivElement | null;
  private _oldCursor: string | null;
  private _oldCursorSet: boolean;

  constructor(swipeController: SwipeController) {
    this._swipeController = swipeController;

    this._map = null;

    this._controllerActivationListener = null;
    this._controllerDeactivationListener = null;
    this._swipeLocationListener = null;
    this._orientationChangedListener = null;
    this._hoverChangeListener = null;
    this._swipingChangeListener = null;
    this._initialSwipeListener = null;
    this._instructions = null;
    this._oldCursor = null;
    this._oldCursorSet = false;
    this._layerPicker = new CzechLayerPicker(this._swipeController);

    this._controllerActivationListener = this._swipeController.on("Activated", this.handleActivate);
    if (swipeController.map) {
      this.handleActivate(); // controller already active
    }
  }

  /**
   * An event that is fired when the stop button is clicked
   * @event
   */
  public on(event: "StopButtonClicked", callback: () => void): Handle {
    return this._layerPicker.on(event, callback);
  }

  private handleActivate = () => {
    this._map = this._swipeController.map!;

    const layerPickerClassToRemove = "lcdLayerPickerVertical";
    this._layerPicker.leftContainer?.classList.remove(layerPickerClassToRemove);
    this._layerPicker.centerContainer?.classList.remove(layerPickerClassToRemove);
    this._layerPicker.rightContainer?.classList.remove(layerPickerClassToRemove);
    this._layerPicker.leftLayers?.classList.remove(layerPickerClassToRemove);
    this._layerPicker.rightLayers?.classList.remove(layerPickerClassToRemove);

    this._layerPicker.leftContainer?.classList.add(SWIPE_CONTROLLER_CLASS);
    this._layerPicker.centerContainer?.classList.add(SWIPE_CONTROLLER_CLASS, SWIPE_LINE_CLASS);
    this._layerPicker.rightContainer?.classList.add(SWIPE_CONTROLLER_CLASS);

    this._instructions = document.createElement("div");
    this._instructions.classList.add(SWIPE_CONTROLLER_CLASS, SWIPE_INSTRUCTIONS_CLASS);
    this._instructions.innerHTML = "Vyberte a potvrďte vrstvy v panelech nad mapou. <br/>Táhněte touto linií" +
                                   " horizontálně či vertikálně pro jejich porovnání."
    this._map.domNode.appendChild(this._instructions);

    this._initialSwipeListener = this._swipeController.on("SwipingChanged", () => {
      this._instructions?.parentNode?.removeChild(this._instructions);
      this._initialSwipeListener?.remove();
    });

    this._orientationChangedListener = this._swipeController.on("SwipeLineOrientationChange", () => {
      this.handleOrientationChange();
      this.handleSwipeLineChange();
    });
    this.handleOrientationChange();

    this._swipeLocationListener = this._swipeController.on("SwipeLineLocationChange", this.handleSwipeLineChange);
    this.handleSwipeLineChange();

    this._hoverChangeListener = this._swipeController.on("HoveringChanged", this.handleHoverChange);
    this.handleHoverChange();
    this._swipingChangeListener = this._swipeController.on("SwipingChanged", this.handleSwipingChange);
    this.handleSwipingChange();

    this._controllerDeactivationListener = this._swipeController.on("Deactivated", this.handleDeactivate);
  };

  private handleDeactivate = () => {
    this.updateCursor();
    this._orientationChangedListener?.remove();
    this._orientationChangedListener = null;
    this._initialSwipeListener?.remove();
    this._initialSwipeListener = null;
    this._instructions?.parentNode?.removeChild(this._instructions);
    this._instructions = null;
  };

  private handleSwipeLineChange = (): void => {
    if (!this._layerPicker.leftContainer || !this._layerPicker.centerContainer || !this._layerPicker.rightContainer) {
      return; // already destroyed
    }
    const {swipeLineOrientation, swipeLineLocation} = this._swipeController;
    const horizontal = swipeLineOrientation === SwipeLineOrientation.VERTICAL;
    if (horizontal) {
      this._layerPicker.leftContainer.style.transform = `translate(${swipeLineLocation.x}px, 0px) translateX(-100%)`;
      this._layerPicker.centerContainer.style.transform = `translate(${swipeLineLocation.x}px, 0px) translateX(-50%)`;
      this._layerPicker.rightContainer.style.transform = `translate(${swipeLineLocation.x}px, 0px)`;
    } else {
      this._layerPicker.leftContainer.style.transform = `translate(0px, ${swipeLineLocation.y}px) translateY(-100%)`;
      this._layerPicker.centerContainer.style.transform = `translate(0px, ${swipeLineLocation.y}px) translateY(-50%)`;
      this._layerPicker.rightContainer.style.transform = `translate(0px, ${swipeLineLocation.y}px)`;
    }
  };

  private handleOrientationChange = (): void => {
    const horizontal = this._swipeController.swipeLineOrientation === SwipeLineOrientation.HORIZONTAL;
    [
      this._layerPicker.leftContainer,
      this._layerPicker.centerContainer,
      this._layerPicker.rightContainer,
      this._layerPicker.leftLayers,
      this._layerPicker.rightLayers
    ].forEach(elem => {
      if (elem) {
        elem.classList.toggle(SWIPE_HORIZONTAL_CLASS, horizontal);
        elem.classList.toggle(SWIPE_VERTICAL_CLASS, !horizontal);
      }
    });
    this.updateCursor();
  };

  private handleHoverChange = () => {
    this._layerPicker.centerContainer?.classList.toggle(SWIPE_HOVER_CLASS, this._swipeController.hovering);
    this.updateCursor();
  };

  private handleSwipingChange = () => {
    this._layerPicker.centerContainer?.classList.toggle(SWIPE_SWIPING_CLASS, this._swipeController.swiping);
    this.updateCursor();
  };

  private updateCursor() {
    if (this._map) {
      const show = this._swipeController.swiping || this._swipeController.hovering;
      const cursor = this._swipeController.swipeLineOrientation === SwipeLineOrientation.VERTICAL ? "col-resize" : "row-resize";
      const newCursor = show ? cursor : null;
      if (newCursor) {
        if (!this._oldCursorSet) {
          this._oldCursor = this._map.domNode.style.cursor;
          this._oldCursorSet = true;
        }
        this._map.domNode.style.cursor = cursor;
      } else {
        if (this._oldCursorSet) {
          this._map.domNode.style.cursor = this._oldCursor!;
          this._oldCursorSet = false;
          this._oldCursor = null;
        }
      }
    }
  }

}