import { WebGLMap } from "@luciad/ria/view/WebGLMap";
import { URLUtil } from "@luciad/ria-sample-common/URLUtil";
import { PerspectiveCamera } from "@luciad/ria/view/camera/PerspectiveCamera";
import { getReference } from "@luciad/ria/reference/ReferenceProvider";
import { createPoint } from "@luciad/ria/shape/ShapeFactory";
import { URLParamsView } from "./URLParamsView";

export var ShareView = {

    /**
     * Copy url to clipboard
     * @param {string} elementId id of the DOM element
     */
    copyToClipboard: function (elementId) {

        /* Get the text field */
        var copyText = document.getElementById(elementId);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied tooltip */
        document.getElementById("sp-copied-tooltip").style.display = "inline";
        setTimeout(function () {
            document.getElementById("sp-copied-tooltip").style.display = "none";
        }, 1000);

    }
}

/**
* Create view with camera params
* @param {WebGLMap} map 
* @param {URLParamsView} params Store camera position parameters (map state)
*/
export function createCameraView(map: WebGLMap, params: URLParamsView, options: {
    /**
     * default true
     */
    animate?: boolean,
  } = {}) {
    //Create a new eye point, defined in provided reference
    const reference = getReference(params.reference);
    const eye = createPoint(reference, [params.eyePointX, params.eyePointY, params.eyePointZ]);

    //When the map is a 3D map, the camera will be a perspective camera
    if (map.camera instanceof PerspectiveCamera) {
        //Update the camera
        map.mapNavigator.lookFrom(eye, params.yaw, params.pitch, params.roll, { animate: options.animate ? options.animate : true });
        // console.log('Camera view UPDATED from URL params.')
    }
}

/**
 * Separates camera view params from the URL
 * @returns {URLParamsView} - object with url params for camera setup
 */
export function getCameraParamsFromURL(): URLParamsView {
    const queryParameters = URLUtil.queryParameters;
    var cameraViewParams = {
        "reference": queryParameters.reference,
        "eyePointX": Number.parseFloat(queryParameters.x),
        "eyePointY": Number.parseFloat(queryParameters.y),
        "eyePointZ": Number.parseFloat(queryParameters.z),
        "yaw": Number.parseFloat(queryParameters.yaw),
        "pitch": Number.parseFloat(queryParameters.pitch),
        "roll": Number.parseFloat(queryParameters.roll)
    };
    return cameraViewParams;
}

/**
 * Get current map state return them as ULRParamsView object
 * @param {WebGLMap} map - main map window
 * @returns {URLParamsView} - object with url params for camera setup
 */
export function getActualMapState(map: WebGLMap): URLParamsView {
    let state = map.saveState();
    var cameraViewParams = {
        "reference": state.reference,
        "eyePointX": state.transformation3D.eyePointX,
        "eyePointY": state.transformation3D.eyePointY,
        "eyePointZ": state.transformation3D.eyePointZ,
        "yaw": state.transformation3D.yaw,
        "pitch": state.transformation3D.pitch,
        "roll": state.transformation3D.roll
    };
    return cameraViewParams;
}