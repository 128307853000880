import "@luciad/ria-sample-common/controllers/ui/SampleLayerPicker.css";
import "./CzechLayerPicker.css";
import {Map} from "@luciad/ria/view/Map";
import {Handle} from "@luciad/ria/util/Evented";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerTreeVisitor} from "@luciad/ria/view/LayerTreeVisitor";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {SwipeController} from "@luciad/ria/view/controller/SwipeController";
import {FlickerController} from "@luciad/ria/view/controller/FlickerController";
import {EventedSupport} from "@luciad/ria/util/EventedSupport";

const LAYER_PICKER_CLASS = "lcdLayerPicker";
const LAYER_PICKER_TOP_LEFT_CONTAINER_CLASS = "lcdLayerPickerTopLeftContainer";
const LAYER_PICKER_CENTER_CONTAINER_CLASS = "lcdLayerPickerCenterContainer";
const LAYER_PICKER_TOP_RIGHT_CONTAINER_CLASS = "lcdLayerPickerTopRightContainer";
const LAYER_PICKER_BUTTON_CLASS = "lcdLayerPickerButton";
const LAYER_PICKER_STOP_BUTTON_CLASS = "lcdLayerPickerStopButton";
const LAYER_PICKER_CONFIRM_SELECTION_BUTTON_CLASS = "lcdConfirmSelectionButton";
const LAYER_PICKER_EDIT_SELECTION_BUTTON_CLASS = "lcdEditSelectionButton";
const LAYER_PICKER_LAYERS_CLASS = "lcdLayerPickerLayers";
const LAYER_PICKER_LAYERS_LEFT_CLASS = "lcdLayerPickerLayersLeft";
const LAYER_PICKER_LAYERS_RIGHT_CLASS = "lcdLayerPickerLayersRight";
const LAYER_PICKER_LAYERS_OPEN_CLASS = "lcdLayerPickerLayersOpen";
const LAYER_PICKER_LAYERS_CLOSED_CLASS = "lcdLayerPickerLayersClosed";
const LAYER_PICKER_LAYER_ITEM_CLASS = "lcdLayerPickerLayerItem";
const LAYER_PICKER_LAYER_ITEM_SELECTED_CLASS = "lcdLayerPickerLayerItemSelected";
const LAYER_PICKER_LAYER_ITEM_DESELECTED_CLASS = "lcdLayerPickerLayerItemDeselected";
const LAYER_PICKER_VERTICAL_CLASS = "lcdLayerPickerVertical";

const renderLayerList = (parent: HTMLDivElement, allLayers: Layer[], selectedLayers: Layer[], isSelecting: boolean, nameSuffix: string, handleClick: (layer: Layer, wasSelected: boolean) => void): void => {
  parent.innerHTML = ""; // clear previous children
  for (const layer of allLayers) {
    if (!layer.visible) {
      continue;
    }
    const selected = selectedLayers.indexOf(layer) >= 0;
    if (!selected && !isSelecting) {
      continue;
    }
    const item = document.createElement("label");
    item.classList.toggle(LAYER_PICKER_LAYER_ITEM_SELECTED_CLASS, selected);
    item.classList.toggle(LAYER_PICKER_LAYER_ITEM_DESELECTED_CLASS, !selected);
    item.classList.add(LAYER_PICKER_LAYER_ITEM_CLASS);
    item.innerText = layer.label;
    if (isSelecting) {
      item.htmlFor = layer.id + nameSuffix;
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.id = layer.id + nameSuffix;
      checkbox.checked = selected;
      item.appendChild(checkbox);
      const afterSpan = document.createElement("span"); // replacement for styling the native checkbox
      item.appendChild(afterSpan);

      item.addEventListener('click', () => {
        handleClick(layer, !selected);
      })
    }

    parent.appendChild(item);
  }
};

/**
 * A sample UI to choose layers to visually compare.
 */
export class CzechLayerPicker {


  private _stopButton: HTMLDivElement | null;
  private _editSelectionButton: HTMLDivElement | null;
  private _confirmSelectionButton: HTMLDivElement | null;
  private _leftLayers: HTMLDivElement | null;
  private _rightLayers: HTMLDivElement | null;
  private readonly _controller: SwipeController | FlickerController;
  private _map: Map | null;

  private _controllerActivationListener: Handle | null;
  private _controllerDeactivationListener: Handle | null;
  private _layersChangeListener: Handle | null;
  private _layerAddedListener: Handle | null;
  private _layerMovedListener: Handle | null;
  private _layerRemovedListener: Handle | null;
  private _layerVisibileListeners: Handle[];
  private _layerPickerOpen: boolean;

  private _topLeftContainer: HTMLDivElement | null;
  private _centerContainer: HTMLDivElement | null;
  private _topRightContainer: HTMLDivElement | null;

  private _eventedSupport: EventedSupport;

  constructor(controller: SwipeController | FlickerController) {
    this._controller = controller;
    this._eventedSupport = new EventedSupport(["StopButtonClicked"]);

    this._centerContainer = null;

    this._stopButton = null;
    this._editSelectionButton = null;
    this._confirmSelectionButton = null;
    this._leftLayers = null;
    this._rightLayers = null;
    this._map = null;

    this._controllerActivationListener = null;
    this._controllerDeactivationListener = null;
    this._layersChangeListener = null;
    this._layerAddedListener = null;
    this._layerMovedListener = null;
    this._layerRemovedListener = null;
    this._layerVisibileListeners = [];
    this._layerPickerOpen = false;

    this._topLeftContainer = null;
    this._topRightContainer = null;

    this._controllerActivationListener = this._controller.on("Activated", this.handleActivate);
    if (controller.map) {
      this.handleActivate(); // controller already active
    }
  }

  private handleActivate = () => {
    this._map = this._controller.map!;

    this._topLeftContainer = document.createElement("div");
    this._topLeftContainer.classList.add(LAYER_PICKER_CLASS, LAYER_PICKER_TOP_LEFT_CONTAINER_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._map.domNode.appendChild(this._topLeftContainer);

    this._centerContainer = document.createElement("div");
    this._centerContainer.classList.add(LAYER_PICKER_CLASS, LAYER_PICKER_CENTER_CONTAINER_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._map.domNode.appendChild(this._centerContainer);

    this._topRightContainer = document.createElement("div");
    this._topRightContainer.classList.add(LAYER_PICKER_CLASS, LAYER_PICKER_TOP_RIGHT_CONTAINER_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._map.domNode.appendChild(this._topRightContainer);

    this._stopButton = document.createElement("div");
    this._stopButton.classList.add(LAYER_PICKER_BUTTON_CLASS, LAYER_PICKER_STOP_BUTTON_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._stopButton.innerText = "✕";
    this._stopButton.title = "Zrušit";
    this._stopButton.addEventListener("click", () => {
      this._eventedSupport.emit("StopButtonClicked");
    });
    this._centerContainer.appendChild(this._stopButton);

    this._editSelectionButton = document.createElement("div");
    this._editSelectionButton.classList.add(LAYER_PICKER_BUTTON_CLASS, LAYER_PICKER_EDIT_SELECTION_BUTTON_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._editSelectionButton.innerText = "🖉";
    this._editSelectionButton.title = "Upravit výběr";
    this._editSelectionButton.addEventListener("click", this.openLayerPicker);
    this._centerContainer.appendChild(this._editSelectionButton);

    this._confirmSelectionButton = document.createElement("div");
    this._confirmSelectionButton.classList.add(LAYER_PICKER_BUTTON_CLASS, LAYER_PICKER_CONFIRM_SELECTION_BUTTON_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._confirmSelectionButton.innerText = "✓";
    this._confirmSelectionButton.title = "Potvrdit výběr";
    this._confirmSelectionButton.addEventListener("click", () => {
      this.closeLayerPicker();
    });
    this._centerContainer.appendChild(this._confirmSelectionButton);

    this._leftLayers = document.createElement("div");
    this._leftLayers.classList.add(LAYER_PICKER_LAYERS_CLASS, LAYER_PICKER_LAYERS_LEFT_CLASS, LAYER_PICKER_LAYERS_CLOSED_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._leftLayers.addEventListener("click", this.openLayerPicker);
    this._topLeftContainer.appendChild(this._leftLayers);

    this._rightLayers = document.createElement("div");
    this._rightLayers.classList.add(LAYER_PICKER_LAYERS_CLASS, LAYER_PICKER_LAYERS_RIGHT_CLASS, LAYER_PICKER_LAYERS_CLOSED_CLASS, LAYER_PICKER_VERTICAL_CLASS);
    this._rightLayers.addEventListener("click", this.openLayerPicker);
    this._topRightContainer.appendChild(this._rightLayers);

    let centerX = this._map.viewSize[0] / 2;
    this._topLeftContainer.style.transform = `translate(${centerX}px, 0px) translateX(-100%)`;
    this._centerContainer.style.transform = `translate(${centerX}px, 0px) translateX(-50%)`;
    this._topRightContainer.style.transform = `translate(${centerX}px, 0px)`;

    this._layerAddedListener = this._map.layerTree.on("NodeAdded", this.handleLayersChange);
    this._layerMovedListener = this._map.layerTree.on("NodeMoved", this.handleLayersChange);
    this._layerRemovedListener = this._map.layerTree.on("NodeRemoved", this.handleLayersChange);

    this._layersChangeListener = this._controller.on("LayersChange", this.handleLayersChange);
    this.handleLayersChange();

    this._controllerDeactivationListener = this._controller.on("Deactivated", this.handleDeactivate);

    if (this._controller.layers.length === 0 || (this._controller.layers[0].length === 0 && this._controller.layers[1].length === 0)) {
      this.openLayerPicker();
    }
  };

  get leftContainer(): HTMLDivElement | null {
    return this._topLeftContainer;
  }

  get centerContainer(): HTMLDivElement | null {
    return this._centerContainer;
  }

  get rightContainer(): HTMLDivElement | null {
    return this._topRightContainer;
  }

  get leftLayers(): HTMLDivElement | null {
    return this._leftLayers;
  }

  get rightLayers(): HTMLDivElement | null {
    return this._rightLayers;
  }

  public openLayerPicker = (): void => {
    this._layerPickerOpen = true;
    if (!this._rightLayers || !this._leftLayers || !this._map) {
      return;
    }

    if (this._editSelectionButton) {
      this._editSelectionButton.style.display = "none";
    }
    this._leftLayers.removeEventListener("click", this.openLayerPicker);
    this._rightLayers.removeEventListener("click", this.openLayerPicker);

    this.handleLayersChange();
  };

  public closeLayerPicker = (): void => {
    this._layerPickerOpen = false;
    if (!this._rightLayers || !this._leftLayers || !this._map) {
      return;
    }

    if (this._editSelectionButton) {
      this._editSelectionButton.style.display = "block";
    }
    this._leftLayers.addEventListener("click", this.openLayerPicker);
    this._rightLayers.addEventListener("click", this.openLayerPicker);

    this.handleLayersChange();
  };

  /**
   * An event that is fired when the stop button is clicked
   * @event
   */
  public on(event: "StopButtonClicked", callback: () => void): Handle {
    return this._eventedSupport.on(event, callback);
  }

  private handleDeactivate = () => {
    this._layerAddedListener?.remove();
    this._layerAddedListener = null;
    this._layerMovedListener?.remove();
    this._layerMovedListener = null;
    this._layerRemovedListener?.remove();
    this._layerRemovedListener = null;
    this._layersChangeListener?.remove();
    this._layersChangeListener = null;
    for (const visibleListener of this._layerVisibileListeners) {
      visibleListener.remove();
    }
    this._layerVisibileListeners = [];
    this._topLeftContainer?.parentNode?.removeChild(this._topLeftContainer);
    this._topLeftContainer = null;
    this._centerContainer?.parentNode?.removeChild(this._centerContainer);
    this._centerContainer = null;
    this._topRightContainer?.parentNode?.removeChild(this._topRightContainer);
    this._topRightContainer = null;
    this._map?.domNode.removeEventListener("click", this.closeLayerPicker);
    this._map = null;
  };

  private handleLayersChange = (): void => {
    if (!this._leftLayers || !this._rightLayers || !this._confirmSelectionButton || !this._map) {
      return;
    }
    for (const visibleListener of this._layerVisibileListeners) {
      visibleListener.remove();
    }
    this._layerVisibileListeners = [];
    this._leftLayers.innerHTML = ""; // clear children
    this._rightLayers.innerHTML = ""; // clear children

    this._leftLayers.classList.toggle(LAYER_PICKER_LAYERS_CLOSED_CLASS, !this._layerPickerOpen);
    this._leftLayers.classList.toggle(LAYER_PICKER_LAYERS_OPEN_CLASS, this._layerPickerOpen);
    this._rightLayers.classList.toggle(LAYER_PICKER_LAYERS_CLOSED_CLASS, !this._layerPickerOpen);
    this._rightLayers.classList.toggle(LAYER_PICKER_LAYERS_OPEN_CLASS, this._layerPickerOpen);
    this._confirmSelectionButton.classList.toggle(LAYER_PICKER_LAYERS_OPEN_CLASS, this._layerPickerOpen);
    this._confirmSelectionButton.classList.toggle(LAYER_PICKER_LAYERS_CLOSED_CLASS, !this._layerPickerOpen);

    const layers = this._controller.layers;
    let leftControllerLayers: Layer[] = [];
    let rightControllerLayers: Layer[] = [];
    if (layers.length === 2) {
      leftControllerLayers = layers[0];
      rightControllerLayers = layers[1];
    }
    const allLayersTopDown: Layer[] = [];
    const leftLayersTopDown: Layer[] = [];
    const rightLayersTopDown: Layer[] = [];
    const layerTreeVisitor: LayerTreeVisitor = {
      visitLayer: (layer: Layer): LayerTreeVisitor.ReturnValue => {
        allLayersTopDown.push(layer);
        if (leftControllerLayers.indexOf(layer) >= 0) {
          leftLayersTopDown.push(layer);
        }
        if (rightControllerLayers.indexOf(layer) >= 0) {
          rightLayersTopDown.push(layer);
        }
        return LayerTreeVisitor.ReturnValue.CONTINUE;
      },
      visitLayerGroup(layerGroup: LayerGroup): LayerTreeVisitor.ReturnValue {
        layerGroup.visitChildren(layerTreeVisitor, LayerTreeNode.VisitOrder.TOP_DOWN);
        return LayerTreeVisitor.ReturnValue.CONTINUE;
      }
    };
    this._map.layerTree.visitChildren(layerTreeVisitor, LayerTreeNode.VisitOrder.TOP_DOWN);
    for (const layer of allLayersTopDown) {
      const visibleChangedListener = layer.on("VisibilityChanged", () => {
        renderLayerList(this._leftLayers!, allLayersTopDown, leftLayersTopDown, this._layerPickerOpen, "-layerPickerLeft", this.handleLeftLayerSelectionChange);
        renderLayerList(this._rightLayers!, allLayersTopDown, rightLayersTopDown, this._layerPickerOpen, "-layerPickerRight", this.handleRightSelectionChange);
      });
      this._layerVisibileListeners.push(visibleChangedListener);
    }
    renderLayerList(this._leftLayers, allLayersTopDown, leftLayersTopDown, this._layerPickerOpen, "-layerPickerLeft", this.handleLeftLayerSelectionChange);
    renderLayerList(this._rightLayers, allLayersTopDown, rightLayersTopDown, this._layerPickerOpen, "-layerPickerRight", this.handleRightSelectionChange);
  };

  private handleLayerSelectionChange = (layer: Layer, selected: boolean, layersOfSide: Layer[], otherSideLayers: Layer[]): [Layer[], Layer[]] => {
    let newLayersOfSide: Layer[];
    let newLayersOfOtherSide: Layer[];
    if (selected) {
      newLayersOfSide = [...layersOfSide, layer];
      newLayersOfOtherSide = otherSideLayers.filter(otherSideLayer => otherSideLayer.id !== layer.id);
    } else {
      newLayersOfSide = layersOfSide.filter(layerOfSide => layerOfSide.id !== layer.id);
      newLayersOfOtherSide = otherSideLayers;
    }
    return [newLayersOfSide, newLayersOfOtherSide];
  };

  private handleLeftLayerSelectionChange = (layer: Layer, selected: boolean) => {
    const layers = this._controller.layers;
    let leftLayers: Layer[] = [];
    let rightLayers: Layer[] = [];
    if (layers.length === 2) {
      leftLayers = layers[0];
      rightLayers = layers[1];
    }
    const [newLeftLayers, newRightLayers] = this.handleLayerSelectionChange(layer, selected, leftLayers, rightLayers);
    this._controller.layers = [newLeftLayers, newRightLayers];
  };

  private handleRightSelectionChange = (layer: Layer, selected: boolean) => {
    const layers = this._controller.layers;
    let leftLayers: Layer[] = [];
    let rightLayers: Layer[] = [];
    if (layers.length === 2) {
      leftLayers = layers[0];
      rightLayers = layers[1];
    }
    const [newRightLayers, newLeftLayers] = this.handleLayerSelectionChange(layer, selected, rightLayers, leftLayers);
    this._controller.layers = [newLeftLayers, newRightLayers];
  };

}