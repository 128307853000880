import { WebGLMap } from "@luciad/ria/view/WebGLMap";
import { CitySource } from "./layers/CitySource";
import { Source } from "./layers/Source";
import { Navigator } from "./Navigator";
import { getAppConfig } from "./AppConfig";
import { isSafari } from "./BrowserUtil";
import { createTransformation } from "@luciad/ria/transformation/TransformationFactory";
import { getReference } from "@luciad/ria/reference/ReferenceProvider";
import { LocationMode } from "@luciad/ria/transformation/LocationMode";
import { LayerTreeNode } from "@luciad/ria/view/LayerTreeNode";
import { RasterLayer } from "@luciad/ria/view/RasterLayer";
import { createCameraView, getCameraParamsFromURL, getActualMapState } from "./sharepanel/ShareView";
import { SourceMapDevToolPlugin } from "webpack";

export class ContentManager {
  private readonly _map: WebGLMap;
  private readonly _navigator: Navigator;
  private _citySources: CitySource[];
  private readonly _basemapAddedPromise: Promise<void>;
  private _activeSource: CitySource | null;
  private _activeSourceChangedListener: ActiveSourceChangedListener;
  private _baseMapOn: boolean = true;
  private _basemapLayers: LayerTreeNode[] = [];
  // /**
  //  * The minimum distance between camera and the terrain at which the basemap layers are visible.
  //  */
  // private static BASEMAP_ON_THRESHOLD = 100;

  constructor(map: WebGLMap) {
    this._map = map;
    this._navigator = new Navigator(map);
    getAppConfig().then(
      appConfig => this._citySources = appConfig.citiesSources.map(sourceOptions => new CitySource(sourceOptions)));
    this._basemapAddedPromise = this.addBasemap();
    this._navigator.navigateHome();

    // Reflect browsing history
    window.addEventListener("popstate", (event) => {
      this.setActiveSource(event.state.sourceId, { pushState: false, navigate: true });
    });

    // Change URL when mape moved/rotated
    map.on("MapChange", () => {
      // console.log('Map changed');
      this.setUrlFromMapParams(map, { pushState: true });
    });

    // const transformation = createTransformation(map.reference, getReference("EPSG:4326"))
    //
    // const toMapTransformation = map.getViewToMapTransformation(LocationMode.TERRAIN);
    // let zMap: number;
    // let zEllipsoid: number;
    // map.on("MapChange", () => {
    //   zMap = transformation.transform(toMapTransformation.transform(map.camera.eyePoint)).z;
    //   zEllipsoid = transformation.transform(map.camera.eyePoint).z;
    //   this.setBasemapOn(Math.abs(zMap - zEllipsoid) > ContentManager.BASEMAP_ON_THRESHOLD);
    // });
  }

  private async addBasemap() {
    const appConfig = await getAppConfig();
    const layers = await new Source(appConfig.basemap).layers;
    layers.forEach(layer => {
      this._basemapLayers.push(layer);
      this._map.layerTree.addChild(layer, "bottom");
    });
  }

  public async setActiveSource(sourceId?: string, options: {
    /**
     * default true
     */
    animate?: boolean,
    /**
     * default true
     */
    pushState?: boolean,
    /**
     * default true
     */
    navigate?: boolean
  } = { animate: true, pushState: true, navigate: true }) {
    if (options.pushState !== false) {
      history.pushState({ sourceId: sourceId }, "", "?" + (isSafari() ? "webgl=2&" : "") + "obec=" + sourceId);
    }
    if (this._activeSource) {
      await this.removeSourceFromMap(this._activeSource);
      this._activeSource = null;
    }
    if (!sourceId) {
      return;
    }
    await this._basemapAddedPromise;
    const requestedSource = this._citySources.find(source => source.id === sourceId)!;
    this._activeSource = requestedSource;
    this._activeSourceChangedListener?.onActiveSourceChanged(requestedSource);
    if (!requestedSource) {
      return Promise.resolve();
    }
    const layers = await requestedSource.layers;
    layers.reverse().forEach(layer => this._map.layerTree.addChild(layer));
    if (options.navigate) {
      await this._navigator.navigateTo(requestedSource, options.animate ? options.animate : true).catch(() => null);
    }

    // Activate corresponding city button - highlight the frame
    let button = document.querySelectorAll("button#" + sourceId);
    button[0].classList.add("active");

  }

  public set activeSourceChangedListener(value: ActiveSourceChangedListener) {
    this._activeSourceChangedListener = value;
  }

  private async removeSourceFromMap(source: Source) {
    const layers = await source.layers;
    layers.forEach(layer => this._map.layerTree.removeChild(layer));
  }

  private setBasemapOn(on: boolean) {
    if (this._baseMapOn === on) {
      return;
    }
    this._baseMapOn = on;
    this._basemapLayers.forEach(layer => {
      const rasterStyle = (layer as RasterLayer).rasterStyle;
      if (!rasterStyle) {
        return;
      }
      (layer as RasterLayer).rasterStyle = {
        alpha: on ? 1 : 0
      }
    })
  }

 /**
 * Set url after map action (move, rotation) based on map state so that the url can be shared and view reproduced. Includes sourceId (obec) also.
 * @param {WebGLMap} map - main map window
 */
  public async setUrlFromMapParams(map: WebGLMap, options: { pushState?: boolean } = { pushState: true }) {

    let state = map.saveState();
    var queryParams = new URLSearchParams(map.domNode.ownerDocument.location.search);
    let obec = queryParams.get("obec");
    
    let updatedUrlFromCameraParams =
      window.location.protocol
        + "//" + window.location.host
        + window.location.pathname
        + "?obec=" + obec
        + "&reference=" + state.reference
        + "&x=" + state.transformation3D.eyePointX
        + "&y=" + state.transformation3D.eyePointY
        + "&z=" + state.transformation3D.eyePointZ
        + "&yaw=" + state.transformation3D.yaw
        + "&pitch=" + state.transformation3D.pitch
        + "&roll=" + state.transformation3D.roll;
    
    // console.log('Called, url: ', updatedUrlFromCameraParams);
    
    // Store browsing history
    if (options.pushState !== false) {
      window.history.pushState({ url: updatedUrlFromCameraParams }, "", updatedUrlFromCameraParams);
      history.pushState({ url: updatedUrlFromCameraParams }, "", updatedUrlFromCameraParams);
    }
  }
}

export interface ActiveSourceChangedListener {
  onActiveSourceChanged(source: CitySource): void;
}