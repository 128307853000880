import { SourceOptions } from "./layers/Source";
import { CitySourceOptions } from "./layers/CitySource";

export interface AppConfig {
  camera: CameraOptions,
  citiesSources: CitySourceOptions[],
  basemap: SourceOptions
}

interface CameraOptions {
  defaultPosition: {
    /**
     * the coordinate of the point, expressed as an array. (e.g. "[15,50,1000]") in WGS84 reference
     */
    eyePoint: number[],
    /**
     * the desired yaw angle from the eye point to the reference point (in degrees).
     * This is similar to turning your head left to right.
     * 0 means looking north, and the degrees count clockwise.
     */
    yaw: number,
    /**
     * the desired pitch angle from the eye point to the reference point (in degrees).
     * This value must be between -89 and 89 degrees.
     * This is similar to nodding your head up and down.
     * -89 means looking straight down, 0 is looking straight ahead to the horizon, and 89 is degrees is looking
     * straight up.
     */
    pitch: number,
    /**
     * the desired roll angle of the viewer around its line-of-sight axis (in degrees).
     * This is similar to turning your entire head upside down (if you could). The rotation is clockwise.
     */
    roll: number
  };
}

let appConfigPromise;

export async function getAppConfig(): Promise<AppConfig> {
  if (!appConfigPromise) {
    appConfigPromise = fetch("./appConfig.json")
      .then(response => response.json())
      .then(obj => {
        return obj;
      });
  }
  return appConfigPromise;
}