import {RasterTileSetLayer} from "@luciad/ria/view/tileset/RasterTileSetLayer";
import {WMTSTileSetModel} from "@luciad/ria/model/tileset/WMTSTileSetModel";
export var WMTSDataLoader = {
  /**
   * Creates a layer for WMTS data.
   *
   * Summary:
   * - Create a WMTS tileset model for a given url, layer identifier / layer name and options.
   * - Create a {@link luciad/view/tileset/RasterTileSetLayer}
   */
  createLayer: function(url, layerIdentifier, layerName, options) {
    return WMTSTileSetModel.createFromURL(url, {
      layer: layerIdentifier
    }, options).then(function(model) {
      return new RasterTileSetLayer(model, {
        label: layerName + " (WMTS)"
      });
    });
  },

  createLayerFromCapabilities: function(capabilities, layerName, layerConfig, options) {
    return new RasterTileSetLayer(WMTSTileSetModel.createFromCapabilities(capabilities, layerConfig, options), {
      label: layerName + " (WMTS)"
    });
  }
};
