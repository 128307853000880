window.resetMapSize = function resetMapSize() {
  var ui = document.getElementById("after");
  if (ui) {
    document.getElementById("map").style.height
        = window.innerHeight
          - ui.offsetHeight
          - parseInt(window.getComputedStyle(ui).getPropertyValue('margin-top'))
          - parseInt(window.getComputedStyle(ui).getPropertyValue('margin-bottom'))
          + "px";
  }
};

var disableUI = function disableUI(selector, type) {
  var elements = document.querySelectorAll(selector);
  var element;
  for (var i = 0; i < elements.length; i++) {
    element = elements[i];
    element.disabled = "disabled";
    element.title = "Feature not available on " + type + " maps";
  }
};

window.UILoaded = false;

function bootstrapUI() {
  if (!UILoaded) {
    window.UILoaded = true;
    var fe = [].forEach;
    // Toggle info panel
    fe.call(document.getElementsByClassName("info-button"), function(element) {
      element.addEventListener("click", function(e) {
        fe.call(document.getElementsByClassName("info-button"), function(element) {
          element.classList.toggle("active");
        });
        document.body.classList.toggle("infopanel-active");
      }, false);
    });

    // Add bootstrap to forms
    var enrichform = function(control) {
      return function(child) {
        child.classList.add(control);
      }
    };
    fe.call(document.querySelectorAll(":not(.nobootstrap) > select"), enrichform("form-control"));
    fe.call(document.querySelectorAll(":not(.nobootstrap) > button"), enrichform("form-control"));
    fe.call(document.querySelectorAll(":not(.nobootstrap) > input"), enrichform("form-control"));
    fe.call(document.querySelectorAll(":not(.nobootstrap) > input[type=checkbox]"), enrichform("checkbox"));
    fe.call(document.querySelectorAll(":not(.nobootstrap) > input[type=radio]"), enrichform("radio"));

    fe.call(document.getElementsByClassName("fullscreen-button"), function(element) {
      element.addEventListener('click', function(e) {
        if (!isFullscreen()) {
          requestFullscreen(document.body);
        } else {
          cancelFullscreen();
        }
      }, false);
    });

    function toggleFullscreenButtonIcon() {
      fe.call(document.getElementsByClassName("fullscreen-button"), function(element) {
        element.classList.toggle('active', isFullscreen());
      });
    }

    document.addEventListener("fullscreenchange", toggleFullscreenButtonIcon, false);
    document.addEventListener("mozfullscreenchange", toggleFullscreenButtonIcon, false);
    document.addEventListener("webkitfullscreenchange", toggleFullscreenButtonIcon, false);
    document.addEventListener("MSFullscreenChange", toggleFullscreenButtonIcon, false);

    // Wire fullscreen button
    function requestFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }

    function cancelFullscreen() {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    function isFullscreen() {
      return !!(document.fullscreenElement || // alternative standard method
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement); // current working methods
    }

    // Change map UI size on window resize
    window.addEventListener('resize', function() {
      resetMapSize();
    }, false);
    resetMapSize();
  }
};

bootstrapUI();

export {disableUI};
