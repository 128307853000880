import { LTSCapabilities } from "@luciad/ria/model/capabilities/LTSCapabilities";
import { FusionTileSetModel } from "@luciad/ria/model/tileset/FusionTileSetModel";
import { RasterTileSetLayer } from "@luciad/ria/view/tileset/RasterTileSetLayer";
import { WMSCapabilities } from "@luciad/ria/model/capabilities/WMSCapabilities";
import { WMSTileSetModel } from "@luciad/ria/model/tileset/WMSTileSetModel";
import { OGC3DTilesModel } from "@luciad/ria/model/tileset/OGC3DTilesModel";
import { TileLoadingStrategy, TileSet3DLayer } from "@luciad/ria/view/tileset/TileSet3DLayer";
import { UrlTileSetModel } from "@luciad/ria/model/tileset/UrlTileSetModel";
import { createBounds } from "@luciad/ria/shape/ShapeFactory";
import { WMTSCapabilities } from "@luciad/ria/model/capabilities/WMTSCapabilities";
import { WMTSTileSetModel } from "@luciad/ria/model/tileset/WMTSTileSetModel";
import { MERCATOR } from "../Reference";
import { LayerOptions, LayerType, LtsOptions, Ogc3DTilesOptions, WmsOptions, WmtsOptions } from "./Source";
import { LayerTreeNode } from "@luciad/ria/view/LayerTreeNode";
import { getReference } from "@luciad/ria/reference/ReferenceProvider";
import { add, color, defaultColor } from "@luciad/ria/util/expression/ExpressionFactory";
import {
  createChainedTransformation,
  createOffsetTransformation
} from "@luciad/ria/transformation/Affine3DTransformation";

export async function createLayer(options: LayerOptions): Promise<LayerTreeNode> {
  switch (options.type) {
    case LayerType.wms:
      return createWmsLayer(options as WmsOptions);
    case LayerType.ogc3d:
      return createOgc3dTilesLayer(options as Ogc3DTilesOptions);
    case LayerType.lts:
      return createLtsLayer(options as LtsOptions);
    case LayerType.wmts:
      return createWmtsLayer(options as WmtsOptions);
    default:
      return Promise.resolve(null);
  }
}

export async function createWmsLayer(options: WmsOptions): Promise<RasterTileSetLayer> {
  const wmsCapabilities = await WMSCapabilities.fromURL(options.url);
  const model = WMSTileSetModel.createFromCapabilities(
    wmsCapabilities,
    [{ layer: options.layer }],
    { reference: getReference(options.reference) }
  );
  return new RasterTileSetLayer(model, {
    label: options.title,
    visible: options.visible
  });
}

export async function createOgc3dTilesLayer(options: Ogc3DTilesOptions): Promise<TileSet3DLayer> {
  const model = await OGC3DTilesModel.create(options.url);
  // console.log('Layer: "' + options.title + '" loaded');
  let tileSet3DLayer = new TileSet3DLayer(model, {
    label: options.title,
    isPartOfTerrain: true,
    visible: options.visible,
    loadingStrategy: TileLoadingStrategy.OVERVIEW_FIRST,
    offsetTerrain: {
      precise: true,
      enabled: true,
      offset: 3
    }
  });

  // Style only when option defined - mainly for meshes
  if (options.brightnessAdd) {
    tileSet3DLayer.meshStyle = {
      colorExpression: add(
        color("rgb(" + options.brightnessAdd + "," + options.brightnessAdd + "," + options.brightnessAdd + ")"),
        defaultColor()),
      lighting: false
    };
  }
  //Configurable mesh offset to limit mesh vs terrain collision
  if (options.offset) {
    tileSet3DLayer.transformation = createOffsetTransformation(
      {x: options.offset[0], y: options.offset[1], z: options.offset[2]},
      model.bounds.focusPoint
    )
  }
  return tileSet3DLayer;
}

export async function createWmtsLayer(options: WmtsOptions): Promise<RasterTileSetLayer> {
  const wmtsCapabilities = await WMTSCapabilities.fromURL(options.url);
  const model = WMTSTileSetModel.createFromCapabilities(
    wmtsCapabilities,
    { layer: options.layer }
  );
  return new RasterTileSetLayer(model, {
    label: options.title,
    visible: options.visible
  });
}

export async function tms(url: string): Promise<RasterTileSetLayer> {
  return new RasterTileSetLayer(new UrlTileSetModel({
    baseURL: url + "{z}-{x}-{-y}",
    reference: MERCATOR,
    bounds: createBounds(MERCATOR, [
      -20037508.34278925,
      40075016.6855785,
      -20037508.34278925,
      40075016.6855785
    ]),
    levelCount: 20,
  }), {});
}

export async function createLtsLayer(options: LtsOptions): Promise<RasterTileSetLayer> {
  const capabilities = await LTSCapabilities.fromURL(options.url);
  const model = FusionTileSetModel.createFromCapabilities(capabilities, capabilities.coverages[0].id);
  // const superGetImage = model.getImage;
  // model.getImage = (tile: TileCoordinate, onSuccess: (tile: TileCoordinate, image: HTMLImageElement) => void,
  //   onError: (tile: TileCoordinate, error?: any) => void, abortSignal: AbortSignal | null) => {
  //   superGetImage(tile, onSuccess, () => {}, abortSignal);
  // };
  return new RasterTileSetLayer(model, {
    label: options.title,
    visible: options.visible
  });
}