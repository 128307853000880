import { LayerTreeControl } from "@luciad/ria-sample-common/control/LayerTreeControl";
import { LayerTreeNode } from "@luciad/ria/view/LayerTreeNode";
import { FeatureLayer } from "@luciad/ria/view/feature/FeatureLayer";
import { RasterTileSetLayer } from "@luciad/ria/view/tileset/RasterTileSetLayer";
import { RasterDataType } from "@luciad/ria/model/tileset/RasterDataType";
import { RasterImageLayer } from "@luciad/ria/view/image/RasterImageLayer";
import { GridLayer } from "@luciad/ria/view/grid/GridLayer";
import { LayerTreeNodeType } from "@luciad/ria/view/LayerTreeNodeType";
import { TileSet3DLayer } from "@luciad/ria/view/tileset/TileSet3DLayer";
import { Layer } from "@luciad/ria/view/Layer";
import { getFitBounds } from "@luciad/ria-sample-common/FitBoundsUtil";

export class CzechLayerTreeControl extends LayerTreeControl {
  _createLayerDiv(layer: LayerTreeNode) {
    // @ts-ignore
    const layerDiv = super._createLayerDiv(layer) as HTMLDivElement;
    const moreElement = layerDiv.getElementsByClassName("layerTreeMoreAction").item(0) as HTMLDivElement;
    moreElement.title = "Další akce";
    const fitLayerElement = layerDiv.getElementsByClassName("layerTreeFitLayerAction").item(0) as HTMLDivElement;
    fitLayerElement.title = "Přiblížit na vrstvu";
    const visibleToggleElement = (layerDiv.getElementsByClassName("visible-toggle").item(0) as HTMLDivElement);
    visibleToggleElement.title = "Změnit viditelnost vrstvy";
    return layerDiv;
  }

  _createLayerIcon(layer: LayerTreeNode) {
    // @ts-ignore
    let iconElement = super._createLayerIcon(layer) as HTMLImageElement;
    if (layer instanceof FeatureLayer) {
      iconElement.title = "Vektorová data";
    } else if (layer instanceof RasterTileSetLayer && layer.model.dataType && layer.model.dataType ===
               RasterDataType.ELEVATION) {
      iconElement.title = "Terén";
    } else if (layer instanceof RasterTileSetLayer || layer instanceof RasterImageLayer) {
      iconElement.title = "Rastr";
    } else if (layer instanceof GridLayer) {
      iconElement.title = "Mřížka";
    } else if (layer.treeNodeType === LayerTreeNodeType.LAYER_GROUP) {
      iconElement.title = "Skupina";
    } else if (layer instanceof TileSet3DLayer) {
      // @ts-ignore
      return this._createIconElement({
        className: "lcdIconShapes",
        title: "3D model"
      });
    } else {
      iconElement.title = "Neznámý typ vrstvy";
    }
    return iconElement;
  }

  setSelected(layer: Layer) {
    super.setSelected(layer);
    // @ts-ignore
    if (this._selectedLayer !== layer || !this._selectedLayer) {
      return;
    }
    // @ts-ignore
    const fitAction = this._getDOMElementForLayerNode(layer).querySelector(".layerTreeFitLayerAction");
    getFitBounds(layer).then((fitBounds) => {
      fitAction.classList.toggle("layerTreeDisabled", !fitBounds);
      fitAction.title = !!fitBounds ? "Přiblížit na vrstvu." : "Data nejsou načtena.";
    });
  }
}