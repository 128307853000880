import { createLayer } from "./LayerLoader";
import { CoordinateReference } from "@luciad/ria/reference/CoordinateReference";
import { LayerTreeNode } from "@luciad/ria/view/LayerTreeNode";

export class Source {
  private readonly _id: string;
  private readonly _layerPromises: Promise<LayerTreeNode>[];

  constructor(options: SourceOptions) {
    this._id = options.id;
    this._layerPromises = options.layers.map(layerOptions => createLayer(layerOptions).catch((e) => console.log("e",e)));
  }

  get id(): string {
    return this._id;
  }

  get layers(): Promise<LayerTreeNode[]> {
    return Promise.all(this._layerPromises).then(layers => layers.filter(layer => layer));
  }
}

export interface SourceOptions {
  id?: string,
  title?: string,
  layers: LayerOptions[]
}

export interface LayerOptions {
  type: LayerType,
  title?: string,
  url: string,
  visible?:boolean,
  supports2D: boolean,
  supports3D: boolean,
}

export interface WmsOptions extends LayerOptions {
  reference: string,
  layer: string
}

export interface Ogc3DTilesOptions extends LayerOptions {
  /**
   * Brightness modification.
   */
  brightnessAdd?: number
  /**
   * [x, y, z] offset in meters where x adds to longitude, y to latitude and z to height.
   */
  offset?: number[]
}

export interface LtsOptions extends LayerOptions {
}

export interface WmtsOptions extends LayerOptions {
  reference: CoordinateReference,
  layer: string,
  visible?:boolean
}

export enum LayerType {
  ogc3d = "ogc3d",
  lts = "lts",
  wms = "wms",
  wmts = "wmts"
}